<template>
  <span v-if="humanizeDate">{{ humanizeDate }}</span>
</template>

<script>
export default {
  props: {
    date: {
      type: [String, Number],
      required: true
    },
    negative: {
      type: [Boolean, String],
      default: true
    },
    unix: {
      type: Boolean,
      default: false
    },
    localize: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    humanizeDate() {
      let dateDiff = null;
      let date = this.$moment()
        .utc()
        .local();
      if (this.unix) {
        dateDiff = this.$moment(this.$moment.unix(this.date)).diff(
          date,
          "seconds"
        );
      } else {
        dateDiff = this.$moment(this.utcToLocalTime(this.date)).diff(
          date,
          "seconds"
        );
      }
      return this.$moment.duration(dateDiff, "seconds").humanize(true);
    }
  },
  methods: {
    utcToLocalTime(date) {
      return this.$moment
        .utc(date)
        .local()
        .format();
    }
  }
};
</script>
